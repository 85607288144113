import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { MatSnackBar } from "@angular/material";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  isLinear = true;

  nameFormGroup: FormGroup;
  emailFormGroup: FormGroup;
  messageFormGroup: FormGroup;

  sent: boolean;

  constructor(private formBuilder: FormBuilder, private http: HttpClient, private snackBar: MatSnackBar) {}

  ngOnInit() {
    this.nameFormGroup = this.formBuilder.group({
      name: ['', Validators.required]
    });
    this.emailFormGroup = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
    this.messageFormGroup = this.formBuilder.group({
      message: ['', Validators.required],
      phone: ['']
    });
  }

  send() {
    const data = {
      name: this.nameFormGroup.get('name').value,
      email: this.emailFormGroup.get('email').value,
      message: this.messageFormGroup.get('message').value,
      phone: this.messageFormGroup.get('phone').value
    };

    const headers = new HttpHeaders()
      .set('Authorization', 'my-auth-token')
      .set('Content-Type', 'application/json');

    this.http.post('https://api.schwaben-contrans.de/sendmail', data, {
      headers: headers
    }).subscribe(
      () => {
        this.snackBar.open('Ihre Nachricht wurde erfolgreich versandt. Wir melden uns so schnell wie möglich.', 'ok', { duration: 5000 });
        this.sent = true;
      },
      error => {
        this.snackBar.open('Ein Fehler ist aufgetreten. Bitte kontaktieren Sie uns per Email an info@schwaben-contrans.de', 'ok', { duration: 5000 });
      }
    );
  }
}
