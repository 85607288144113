import { Injectable } from '@angular/core';
import {Title} from "@angular/platform-browser";
import { SidenavRoute } from "../interfaces/sidenav-route";

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  domain = 'Schwaben-Contrans.de';

  sidenavRoutes: SidenavRoute[] = [
    {
      title: 'Startseite',
      subtitle: 'Gehe zur Startseite',
      icon: 'home',
      route: ''

    },
    {
      title: 'Kontakt',
      subtitle: 'Nehmen Sie Kontakt auf',
      icon: 'account_circle',
      route: '/kontakt'
    },
    {
      title: 'Anfahrt',
      subtitle: 'Anfahrt über Google Maps',
      icon: 'place',
      route: '/anfahrt'
    }
  ];

  hrefRoutes: any = [
    {
      title: 'Mail',
      href: 'mailto:info@schwaben-contrans.de?subject=Transport%20Anfrage'
    }
  ];

  //info@schwaben-contrans.de
  //s.kurz, steffen.kurz..
  //andy.drochner a.drochner

  constructor(public titleService: Title) { }

  setTitle(newTitle: string) {
    this.titleService.setTitle(this.domain + ' - ' + newTitle);
  }

  getDomain() {
    return this.domain;
  }

  getSidenavRoutes() {
    return this.sidenavRoutes;
  }

  getHrefRoutes() {
    return this.hrefRoutes;
  }

  isMobileDeviceSize() {
    return window.innerWidth <= 800;
  }

}
