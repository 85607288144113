import { Component } from '@angular/core';
import { UtilService } from "../../services/util.service";
import { Meta } from "@angular/platform-browser";

@Component({
  selector: 'app-legal-notice',
  templateUrl: './legal-notice.component.html',
  styleUrls: ['./legal-notice.component.scss']
})
export class LegalNoticeComponent {

  constructor(private utilService: UtilService, private meta: Meta) {
    this.utilService.setTitle('Impressum');
    this.meta.updateTag({ name: "description", content: "Impressum" });
    this.meta.updateTag({ name: "keywords", content: "Impressum" });
  }

}
