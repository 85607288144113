import { Component } from '@angular/core';
import { UtilService } from "../../services/util.service";
import { Meta } from "@angular/platform-browser";

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent {

  constructor(private utilService: UtilService, private meta: Meta) {
    this.utilService.setTitle('Datenschutz');
    this.meta.updateTag({ name: "description", content: "" });
    this.meta.updateTag({ name: "keywords", content: "" });
  }

}
