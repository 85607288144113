import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from "./components/home/home.component";
import { LegalNoticeComponent } from "./components/legal-notice/legal-notice.component";
import { PrivacyPolicyComponent } from "./components/privacy-policy/privacy-policy.component";
import { ContactComponent } from "./components/contact/contact.component";
import { JourneyComponent } from "./components/journey/journey.component";
const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'anfahrt',
    component: JourneyComponent
  },
  {
    path: 'kontakt',
    component: ContactComponent
  },
  {
    path: 'impressum',
    component: LegalNoticeComponent
  },
  {
    path: 'datenschutz',
    component: PrivacyPolicyComponent
  },
  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      scrollPositionRestoration: 'enabled',
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
