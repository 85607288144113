import { Component } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  constructor(private utilService: UtilService, private meta: Meta) {
    this.utilService.setTitle('Transportunternehmen');
    this.meta.updateTag({ name: 'description', content: '' });
    this.meta.updateTag({ name: 'keywords', content: '' });
  }

}
